import { MenuItem } from './components/top-menu/top-menu.component';
import { marker as _ } from '@vandres/ngx-translate-extract-marker';

export const menuModules: MenuItem[] = [
  {
    label: _('Dashboard'),
    iconImage: '/assets/menu/top-menu-dashboard.png',
    route: ['/cm/dashboard'],
  },
  {
    label: _('Explore'),
    iconImage: '/assets/menu/top-menu-explore.png',
    route: ['/cm/explore'],
    childs: [
      { label: _('Companies'), route: ['/cm/abm'], iconImage: '/assets/menu/menu-abm.png', feature: 'abm', description: _('See from which companies people are visiting your site, learn from which channels they come and how they engage with your assets') },
      { label: _('Audiences'), route: ['/cm/explore/audience'], iconImage: '/assets/menu/menu-audience.png', feature: 'audience', description: _('Learn how different groups of people behave and compare them') },
      { label: _('Assets'), route: ['/cm/explore/content'], iconImage: '/assets/menu/menu-assets.png', description: _('Learn how different pages, areas or creative assets perform in relation to to goals') },
      { label: _('Journeys'), route: ['/cm/journeys'], iconImage: '/assets/menu/menu-journeys.png', feature: 'journeys', description: _('Explore your individual customer journeys & filter them by source, goal, device, geo & more') },
      { label: _('Goals'), route: ['/cm/explore/goals'], iconImage: '/assets/menu/menu-goals.png', description: _('Learn how different events and goals perform in relation to each other') },
      { label: _('Acquisition'), route: ['/cm/explore/sources'], iconImage: '/assets/menu/menu-acquisition.png', description: _('Learn how different channels, sources or campaigns perform in relation to goals') },
    ]
  },
  {
    label: _('Activate'),
    iconImage: '/assets/menu/top-menu-activate.png',
    route: ['/cm/experience'],
    feature: 'personalization',
    childs: [
      { label: _('Page-level'), route: ['/cm/experience/page'], iconImage: '/assets/menu/activate-page.png', feature: 'aceSupport', description: _('Create personalized page-level experiences and experiments.') },
      { label: _('Journey-level'), route: ['/cm/experience/journey'], iconImage: '/assets/menu/activate-journey.png', description: _('Create and measure  personalized experiences at the journey level which guide audiences to business goals.') },
      {
        label: _('Sales Engagement '),
        route: ['/cm/experience/sales-eng'],
        iconImage: '/assets/menu/activate-sales.png',
        feature: 'showOneToWon',
        description: _('Create curated content streams or hyper-personalized One-to-Won pages that keep your prospects on the road to revenue.')
      },
      { label: _('Analyze'), route: ['/cm/experience/analyze'], iconImage: '/assets/menu/analyze_campaigns.png', description: _('Measure the performance of experiences') },
    ]
  },
  {
    label: _('Configure'),
    iconImage: '/assets/menu/top-menu-configure.png',
    route: ['/cm/configure'],
    permission: 'account:editor',
    childs: [
      { label: _('Account Setup'), route: ['/cm/settings'], iconName: 'settings', iconOutline: true, description: _('Configure general account settings') },
      { label: _('Audiences & Companies'), route: ['/cm/configure/audience'], iconImage: '/assets/menu/menu-audience.png', feature: 'audience', description: _('Define audiences and company target lists you would like to follow and activate') },
      { label: _('Goals & Events'), route: ['/cm/configure/goals'], iconImage: '/assets/menu/menu-goals.png', description: _('Set the goals & events you would like to follow and optimize') },
      { label: _('Website Settings'), route: ['/cm/configure/website'], iconName: 'dynamic_feed', feature: 'report', description: _('Configure website areas, content pools and manage page scan results') },
      { label: _('Reports'), route: ['/cm/reports'], iconImage: '/assets/menu/menu-reports.png', feature: 'report', description: _('Create, schedule and run reports') },
    ]
  }
];
